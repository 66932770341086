<script setup lang="ts">
import { onBeforeMount, computed, ComputedRef } from "vue"
import UserAccountMenu from "@/layouts/components/UserProfileMenu.vue"
import KTThemeModeSwitcher from "@/layouts/components/ThemeModeSwitcher.vue"
import KTIcon from "@/helpers/kt-icon/KTIcon.vue"
import UserAvatarImage from '@/assets/images/avatars/300-1.jpg'
import { useAuthStore } from '@/store/auth'
import { User } from "@/types/auth"
import { getColor } from "@/utils/helpers"

const authStore = useAuthStore()
const { locale } = useI18n()

onBeforeMount(() => {
    if (isAuthenticated.value && user.value === undefined) {
        authStore
            .fetchUserProfile()
            .catch(e => {console.log(e.message)})
    }

    setLanguage()
})

const setLocale = (data: any) => {
    authStore
        .updateUserProfile({data})
        .catch(e => {console.log(e.message)})
        .finally(() => setLanguage())
}

const setLanguage = () => {
    locale.value = user.value?.locale ?? `en`
}

const isAuthenticated = computed<boolean>(() => {
    return authStore.getAccessToken !== undefined
})

const user: ComputedRef<User|undefined> = computed(() => authStore.getUser)
</script>

<template>
    <!--begin::Toolbar wrapper-->
    <div class="d-flex align-items-stretch flex-shrink-0">
        <!--begin::Theme mode-->
        <div class="d-flex align-items-center ms-1 ms-lg-2">
            <!--begin::Menu toggle-->
            <a
                href="#"
                class="btn btn-icon btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end">
                <KTIcon
                    icon-name="night-day"
                    icon-class="theme-light-show fs-2 fs-md-1"/>
                <KTIcon
                    icon-name="moon"
                    icon-class="theme-dark-show fs-2 fs-md-1"/>
            </a>
            <!--begin::Menu toggle-->
            <KTThemeModeSwitcher/>
        </div>
        <!--end::Theme mode-->

        <!--begin::User-->
        <div
            v-if="isAuthenticated && user"
            id="kt_header_user_menu_toggle"
            class="d-flex align-items-center ms-1 ms-lg-3">
            <!--begin::User info-->
            <div
                class="btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end">
                <!--begin::Name-->
                <div
                    class="d-none d-md-flex flex-column align-items-end justify-content-center me-2">
                    <span class="text-muted fs-7 fw-semibold lh-1 mb-2">{{ $t(`hello`) }}</span>
                    <span class="text-gray-900 fs-base fw-bold lh-1">{{ user.firstName }}</span>
                </div>
                <!--end::Name-->

                <!--begin::Symbol-->
                <div class="symbol symbol-30px symbol-md-40px">
                    <img
                        v-if="false"
                        :src="UserAvatarImage"
                        alt="image">

                    <span
                        v-else
                        :class="`bg-light-${getColor(user.firstName)} text-${getColor(user.firstName)}`"
                        class="symbol-label fs-6 fw-bold">
                        {{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}
                    </span>
                </div>
                <!--end::Symbol-->
            </div>
            <!--end::User info-->
            <UserAccountMenu
                :user="user"
                @updateLocale="setLocale"/>
            <!--end::Menu-->
        </div>
        <!--end::User -->
    </div>
    <!--end::Toolbar wrapper-->
</template>
